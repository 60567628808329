@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  overflow-x: hidden;
}

h1{
    font-family: 'Times New Roman', Times, serif;
}

h2{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
}


#header_section{
    background-image: url("./assets/bg-1.jpg");
    background-position: center;
    background-size: contain;
}

#card {
    background-image: url("./assets/bg2.jpg");
    background-position: center;
    background-size: cover;
}

#om{

    animation: ombutton 6s linear infinite;

}

@keyframes ombutton {
    50%{
        transform: translateY(-10px);
    }
}



/* src/components/BackgroundVideo.css */
.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: -1; /* Make sure it stays behind other content */
  }
  
  .background-video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover; /* Ensures the video covers the entire background */
  }
  
  
  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgb(239, 108, 0), rgba(255, 255, 255, 0)); /* Adjust colors and opacity as needed */
    pointer-events: none; /* Allow clicks to pass through to the video */
  }



  /* Home page button css  */
.wrap {
    height: 100%;
    display: flex;
  }
  
  .button {
    min-width: 300px;
    min-height: 60px;
    display: inline-flex;
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.3px;
    font-weight: 700;
    color: white;
    background: linear-gradient(90deg, rgb(73,28,29) 0%, rgb(116, 56, 57) 100%);
    border: none;
    border-radius: 1000px;
    box-shadow: 12px 12px 24px rgba(151, 69, 70, 0.64), 0 0 10px rgba(129, 63, 64, 0.5); /* Added light green shadow */
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: none;
    position: relative;
    padding: 10px;
  }
  
  .button::before {
    content: '';
    border-radius: 1000px;
    min-width: calc(300px + 12px);
    min-height: calc(60px + 12px);
    border: 6px solid rgb(73,28,29); /* Existing border color */
    box-shadow: 0 0 60px rgba(56, 21, 22, 0.64);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .button:hover, 
  .button:focus {
    transform: translateY(-6px);
    box-shadow: 0 0 20px rgba(138, 56, 58, 0.5); /* Added hover shadow effect */
  }
  
  .button:hover::before, 
  .button:focus::before {
    opacity: 1;
  }
  
  .button::after {
    content: '';
    width: 30px; 
    height: 30px;
    border-radius: 100%;
    border: 6px solid rgb(73,28,29);
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
  }
  
  .button:hover::after, 
  .button:focus::after {
    animation: none;
    display: none;
  }
  
  @keyframes ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    100% {
      width: 200px;
      height: 200px;
      opacity: 0;
    }
  }




/* Kumbh mela add banner css  */

.blink{
  animation: blink infinite linear 1s;
}

@keyframes blink{
  0%{
    filter:brightness(.5);
  }
  50%{
    filter:brightness(.8);
  }
  100%{
    filter:brightness(1);
  }
}



/* Slider to animate */
.image-slider {
  animation: scroll 10s linear infinite;
}



/* Keyframes for scrolling effect */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}





.scrollDesign::-webkit-scrollbar {
  width: 12px; /* Scrollbar width */

}

/* Scrollbar track */
.scrollDesign::-webkit-scrollbar-track {
  background: #f0f0f0; /* Track color */
  border-radius: 6px; /* Rounded corners */
}

/* Scrollbar thumb */
.scrollDesign::-webkit-scrollbar-thumb {
  background: rgb(53, 53, 53); /* Scroll handle color */
  border-radius: 6px; /* Rounded corners */
}

/* Hover effect on scrollbar thumb */
.scrollDesign::-webkit-scrollbar-thumb:hover {
  background: black; /* Darker blue on hover */
}



.tentdetailcard{
  transition: opacity 0.5s ease, transform 0.5s ease;
  
}

.cardbg{
  background: repeating-linear-gradient(
    45deg,
    rgba(146, 201, 177, 0.5), /* #92c9b1 with 80% opacity */
    rgba(146, 201, 177, 0.5) 20px,
    rgba(179, 224, 210, 0.5), /* #b3e0d2 with 80% opacity */
    rgba(179, 224, 210, 0.5) 40px
  );}

/* Fade-in Animation */
.fade-in {
  opacity: 1;
  transform: translateX(0);
}

/* Fade-out Animation */
.fade-out {
  opacity: 0;
  transform: translateX(-100%);
}




/* Home page section of kumbh mela  */

#kumbh{
  width: 100%;
  background: url('./kumbhmela/background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#kumbh h2{
  line-height: 1.3;
  letter-spacing: 2px;
}

.spin{
 animation: spin 6s linear infinite;

}

@keyframes spin{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}




/* spark effect css  */


.square{
  position: absolute;
  width: 10px;
  height: 10px;
  background: #ff8623;
  pointer-events: none;
  animation: animate 2s linear forwards;
}

@keyframes animate
{
  0%{
      opacity: 1;
      transform: translate(0, 0);
  }
  100%{
      opacity: 0;
      transform: translate(var(--x), var(--y));
  }
}
